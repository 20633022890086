import React from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import Account from "./profiles/Account";
import Users from "./profiles/Users";
import Settings from "./profiles/Settings";
import EditProfile from "./EditProfile";
import EditAccountPassword from "./EditAccountPassword";
import AddNewHomeInfo from "./profiles/homeInformation/AddNewBanner";
import LoginImage from "./profiles/homeInformation/LoginImage";
import Massagers from "./profiles/massagers/Massagers";
import AddNewLoginImage from "./profiles/homeInformation/AddNewLoginImage";
import AddNewMassagers from "./profiles/massagers/AddNewMassagers";
import Works from "./profiles/works/Works";
import AddNewWork from "./profiles/works/AddNewWork";
import AddNewProducts from './profiles/products/AddNewProducts';
import Products from './profiles/products/Products';
import Partners from "./profiles/partners/Partners";
import AddNewPartners from "./profiles/partners/AddNewPartners";
import Contacts from "./profiles/contacts/Contacts";
import AddNewContact from "./profiles/contacts/AddNewContact";
import Information from "./profiles/homeInformation/Banners";
import AddNewPrices from "./profiles/prices/AddNewPrices";
import Prices from "./profiles/prices/Prices";
import AddNewMinimalPrice from "./profiles/minimalPrice/AddNewPrices";
import MinimalPrices from "./profiles/minimalPrice/Prices";
import Packages from "./profiles/packages/Packages";
import AddNewPackages from "./profiles/packages/AddNewPackages";
import Services from "./profiles/services/Services";
import AddNewServices from "./profiles/services/AddNewServices";
import Histories from "./profiles/homeInformation/Histories";
import AddNewHistory from "./profiles/homeInformation/AddNewHistory";
import Projects from "./profiles/projects/Projects";
import AddNewProjects from "./profiles/projects/AddNewProjects";
import Galleries from "./profiles/galleries/Galleries";
import AddNewGallery from "./profiles/galleries/AddNewGallery";
import AddNewVideoPath from "./profiles/videoPath/AddNewVideoPath";
import VideoPaths from "./profiles/videoPath/VideoPaths";
import Cars from "./profiles/cars/Cars";
import AddNewCars from "./profiles/cars/AddNewCars";
import AddNewOurAdvantages from "./profiles/cars/AddNewOurAdvantages";
import OurAdvantages from "./profiles/cars/OurAdvantages";
import AddNewOurAdvantagesTaxi from "./profiles/cars/AddNewOurAdvantagesTaxi";
import OurAdvantagesTaxi from "./profiles/cars/OurAdvantagesTaxi";

const ProfileNavigate = () => {
    return (
        <Routes>
            <Route path={'/'} element={<Account/>}>
                <Route path={'/'} element={<Navigate to={"/information"}/>}/>
                <Route path={'/information'} element={<Information/>}/>
                <Route path={'/information/add-new-banner'} element={<AddNewHomeInfo/>}/>
                <Route path={'/information/login-image'} element={<LoginImage/>}/>
                <Route path={'/information/add-new-login-image'} element={<AddNewLoginImage/>}/>
                <Route path={'/information/histories'} element={<Histories/>}/>
                <Route path={'/information/add-new-history'} element={<AddNewHistory/>}/>
                <Route path={'/information/galleries'} element={<Galleries/>}/>
                <Route path={'/information/add-new-gallery'} element={<AddNewGallery/>}/>
                <Route path={'/information/video-paths'} element={<VideoPaths/>}/>
                <Route path={'/information/add-new-video-path'} element={<AddNewVideoPath/>}/>
                <Route path={'/messengers'} element={<Massagers/>}/>
                <Route path={'/messengers/add-new-messengers'} element={<AddNewMassagers/>}/>
                <Route path={'/jobs'} element={<Works/>}/>
                <Route path={'/jobs/add-new-jobs'} element={<AddNewWork/>}/>
                <Route path={'/products'} element={<Products/>}/>
                <Route path={'/products/add-new-products'} element={<AddNewProducts/>}/>
                <Route path={'/partners'} element={<Partners/>}/>
                <Route path={'/partners/add-new-partners'} element={<AddNewPartners/>}/>
                <Route path={'/contacts'} element={<Contacts/>}/>
                <Route path={'/contacts/add-new-contacts'} element={<AddNewContact/>}/>
                <Route path={'/prices'} element={<Prices/>}/>
                <Route path={'/prices/add-new-prices'} element={<AddNewPrices/>}/>
                <Route path={'/minimal-prices'} element={<MinimalPrices/>}/>
                <Route path={'/minimal-prices/add-new-minimal-price'} element={<AddNewMinimalPrice/>}/>
                <Route path={'/packages'} element={<Packages/>}/>
                <Route path={'/packages/add-new-packages'} element={<AddNewPackages/>}/>
                <Route path={'/services'} element={<Services/>}/>
                <Route path={'/services/add-new-services'} element={<AddNewServices/>}/>
                <Route path={'/projects'} element={<Projects/>}/>
                <Route path={'/projects/add-new-projects'} element={<AddNewProjects/>}/>
                <Route path={'/cars'} element={<Cars/>}/>
                <Route path={'/cars/add-new-cars'} element={<AddNewCars/>}/>
                <Route path={'/cars/add-new-advantages'} element={<AddNewOurAdvantages/>}/>
                <Route path={'/cars/our-advantages'} element={<OurAdvantages/>}/>
                <Route path={'/cars/add-new-advantages-taxi'} element={<AddNewOurAdvantagesTaxi/>}/>
                <Route path={'/cars/our-advantages-taxi'} element={<OurAdvantagesTaxi/>}/>
                <Route path={'/users/:page'} element={<Users/>}/>
                <Route path={'/settings'} element={<Settings/>}/>
            </Route>
            <Route path={'/edit-profile'} element={<EditProfile/>}/>
            <Route path={'/edit-account-password'} element={<EditAccountPassword/>}/>
            <Route path={'*'} element={<Account/>}/>
        </Routes>
    );
};

export default ProfileNavigate;
