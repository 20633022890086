export class Account {
    static sendProfileStrong(profile) {
        localStorage.setItem('profile', JSON.stringify(profile))
        Account.setNavbarUrlPath('information')
    }

    static sendRole(role) {
        localStorage.setItem('role', JSON.stringify(role))
    }
    static getRole() {
        try {
            const role = localStorage.getItem('role');
            return JSON.parse(role)
        } catch (e) {
            return null;
        }
    }

    static sendTokenStrong(token) {
        localStorage.setItem('token', JSON.stringify(token))
    }

    static getProfileStrong() {
        try {
            const profileString = localStorage.getItem('profile');
            return JSON.parse(profileString)
        } catch (e) {
            return {}
        }
    }

    static getTokenStrong() {
        try {
            return localStorage.getItem('token')
        } catch (e) {
            return null
        }
    }

    static removeStrong() {
        localStorage.removeItem('profile')
        localStorage.removeItem('token')
        localStorage.removeItem('role')
        localStorage.removeItem('nav_url_path_name');
        localStorage.removeItem('nav_sub_menu_url_path_name');
    }

    static getNavbarUrlPath() {
        try {
            const navPath = localStorage.getItem('nav_url_path_name');
            const subMenuPath = localStorage.getItem('nav_sub_menu_url_path_name');
            return {
                navPath,
                subMenuPath
            }
        } catch (e) {
            return null
        }
    }

    static removeNavbarUrlPath() {
        localStorage.removeItem('nav_url_path_name');
        localStorage.removeItem('nav_sub_menu_url_path_name');
    }

    static setNavbarUrlPath(path) {
        localStorage.setItem('nav_url_path_name', JSON.stringify(path))
    }

    static setNavbarUrlPathSub(path) {
        localStorage.setItem('nav_sub_menu_url_path_name', JSON.stringify(path))
    }
}
