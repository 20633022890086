import {MdConstruction} from "react-icons/md";
import {FaFacebookMessenger, FaRegRegistered, FaUsers} from "react-icons/fa";
import {BiBuilding, BiCar, BiPackage} from "react-icons/bi";
import {AiOutlineTeam} from "react-icons/ai";
import {IoIosContacts} from "react-icons/io";
import {GiPriceTag} from "react-icons/gi";
import {GrProjects, GrServices} from "react-icons/gr";

export default [
    {
        id: 1,
        name: 'Information',
        Icon: FaRegRegistered,
        openIcon: true,
        renderItem: true,
        path: 'information',
        subMenu: [
            {
                id: 1_1,
                title: "Add New Banner",
                path: "add-new-banner",
            },
            {
                id: 1_2,
                title: "Add New Login Image",
                path: "add-new-login-image",
            },
            {
                id: 1_3,
                title: "Login Image",
                path: "login-image",
            },
            {
                id: 1_4,
                title: "Add New History",
                path: "add-new-history",
            },
            {
                id: 1_5,
                title: "Histories",
                path: "histories",
            },
            {
                id: 1_6,
                title: "Add New Gallery",
                path: "add-new-gallery",
            },
            {
                id: 1_7,
                title: "Galleries",
                path: "galleries",
            },
            {
                id: 1_8,
                title: "Add New Video Path",
                path: "add-new-video-path",
            },
            {
                id: 1_9,
                title: "Video Paths",
                path: "video-paths",
            },
        ]
    },
    {
        id: 2,
        name: 'Minimal Prices',
        Icon: GiPriceTag,
        openIcon: true,
        renderItem: true,
        path: 'minimal-prices',
        subMenu: [
            {
                id: 2_1,
                title: "Add New Minimal Price",
                path: "add-new-minimal-price",
            }
        ]
    },
    {
        id: 3,
        name: 'Messengers',
        Icon: FaFacebookMessenger,
        openIcon: true,
        renderItem: true,
        path: 'messengers',
        subMenu: [
            {
                id: 3_1,
                title: "Add New Messengers",
                path: "add-new-messengers",
            }
        ]
    },
    {
        id: 4,
        name: 'Jobs',
        path: 'jobs',
        Icon: BiBuilding,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 4_1,
                title: "Add New Jobs",
                path: "add-new-jobs",
            },
        ]
    },
    {
        id: 5,
        name: 'Products',
        path: 'products',
        Icon: MdConstruction,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 5_1,
                title: "Add New Products",
                path: "add-new-products",
            },
        ]
    },
    {
        id: 6,
        name: 'Partners',
        path: 'partners',
        Icon: AiOutlineTeam,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 6_1,
                title: "Add New Partners",
                path: "add-new-partners",
            },
        ]
    },
    {
        id: 7,
        name: 'Contacts',
        path: 'contacts',
        Icon: IoIosContacts,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 7_1,
                title: "Add New Contacts",
                path: "add-new-contacts",
            },
        ]
    },
    {
        id: 8,
        name: 'Prices',
        path: 'prices',
        Icon: GiPriceTag,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 8_1,
                title: "Add New prices",
                path: "add-new-prices",
            },
        ]
    },
    {
        id: 9,
        name: 'Packages',
        path: 'packages',
        Icon: BiPackage,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 9_1,
                title: "Add New Packages",
                path: "add-new-packages",
            },
        ]
    },
    {
        id: 10,
        name: 'Services',
        path: 'services',
        Icon: GrServices,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 9_1,
                title: "Add New Services",
                path: "add-new-services",
            },
        ]
    },
    {
        id: 11,
        name: 'Projects',
        path: 'projects',
        Icon: GrProjects,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 11_1,
                title: "Add New Projects",
                path: "add-new-projects",
            },
        ]
    },
    {
        id: 12,
        name: 'Cars',
        path: 'cars',
        Icon: BiCar,
        openIcon: true,
        renderItem: true,
        subMenu: [
            {
                id: 12_1,
                title: "Add New Cars",
                path: "add-new-cars",
            },
            {
                id: 12_2,
                title: "Our Advantages",
                path: "our-advantages",
            },
            {
                id: 12_3,
                title: "Add New Advantages",
                path: "add-new-advantages",
            },
            {
                id: 12_4,
                title: "Become Taxi Driver",
                path: "our-advantages-taxi",
            },
            {
                id: 12_5,
                title: "Add New Become Taxi Driver",
                path: "add-new-advantages-taxi",
            },
        ]
    },
    {
        id: 88,
        name: 'Users',
        Icon: FaUsers,
        path: "/users/1",
        renderItem: true,
    },
    // {
    //     id: 99,
    //     name: 'Settings',
    //     Icon: IoIosSettings,
    //     path: "settings",
    //     renderItem: true,
    // },
    // {
    //     id: 111,
    //     name: 'Help',
    //     Icon: IoIosHelpCircle,
    //     path: "help",
    //     renderItem: true,
    // },
]