import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import OurAdvantage from "../../../../components/cars/OurAdvantage";
import {Helmet} from "react-helmet";
import LoadingPage from "../../../../components/LoadingPage";
import { ourAdvantagesListRequest } from '../../../../store/actions/ourAdvantages';

function OurAdvantages() {
    const dispatch = useDispatch();

    const [updateItem, setUpdateItem] = useState(null);

    const loading = useSelector(state => state.ourAdvantages.loading);
    const ourAdvantagesList = useSelector(state => state.ourAdvantages.ourAdvantagesList);

    useEffect(() => {
        dispatch(ourAdvantagesListRequest())
    }, []);

    useEffect(() => {
        if (updateItem?.isActive) {
            setTimeout(() => {
                setUpdateItem(null)
            }, 650)
        }
    }, [updateItem]);

    return (
        <div className={'our-advantages childrenWidth'}>
            <Helmet>
                <title>all our advantages</title>
            </Helmet>

            <div className={'cont_cat'}>
                {
                    loading ? <LoadingPage/>
                        : ourAdvantagesList.map(item =>
                            <OurAdvantage
                                updateItem={updateItem}
                                setUpdateItem={setUpdateItem}
                                key={item.id}
                                ourAdvantage={item}/>)
                }
            </div>
        </div>
    );
}

export default OurAdvantages;

